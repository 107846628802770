// extracted by mini-css-extract-plugin
export var active = "ArPlatformMobileProductsMenu-module--active--uuTID";
export var arGradientBlue = "ArPlatformMobileProductsMenu-module--ar-gradient-blue--fRI7F";
export var arGradientLight = "ArPlatformMobileProductsMenu-module--ar-gradient-light--UMTYI";
export var arH1 = "ArPlatformMobileProductsMenu-module--ar-h1--uyX+M";
export var arH1Mobile = "ArPlatformMobileProductsMenu-module--ar-h1-mobile--YyaSN";
export var arH2 = "ArPlatformMobileProductsMenu-module--ar-h2--AcSq2";
export var arH2Mobile = "ArPlatformMobileProductsMenu-module--ar-h2-mobile--2iMZj";
export var arH3 = "ArPlatformMobileProductsMenu-module--ar-h3--578Wm";
export var arH3Mobile = "ArPlatformMobileProductsMenu-module--ar-h3-mobile--MO1nx";
export var arH4 = "ArPlatformMobileProductsMenu-module--ar-h4--2ZtfW";
export var arH4Mobile = "ArPlatformMobileProductsMenu-module--ar-h4-mobile--4u7hd";
export var arH5 = "ArPlatformMobileProductsMenu-module--ar-h5--qt9mE";
export var arH5Mobile = "ArPlatformMobileProductsMenu-module--ar-h5-mobile--FkziQ";
export var arLinkDesktop = "ArPlatformMobileProductsMenu-module--ar-link-desktop--FypOa";
export var arLinkMobile = "ArPlatformMobileProductsMenu-module--ar-link-mobile--odLjl";
export var arParagraphBase = "ArPlatformMobileProductsMenu-module--ar-paragraph-base--26f-n";
export var arParagraphBaseMedium = "ArPlatformMobileProductsMenu-module--ar-paragraph-base-medium--CHgSe";
export var arParagraphSmall = "ArPlatformMobileProductsMenu-module--ar-paragraph-small--6cwoK";
export var arParagraphSmallMedium = "ArPlatformMobileProductsMenu-module--ar-paragraph-small-medium--f3e3D";
export var arParagraphXl = "ArPlatformMobileProductsMenu-module--ar-paragraph-xl--a4cVU";
export var arParagraphXlMedium = "ArPlatformMobileProductsMenu-module--ar-paragraph-xl-medium--VtEww";
export var arParagraphXsmall = "ArPlatformMobileProductsMenu-module--ar-paragraph-xsmall--Hz-JR";
export var arParagraphXsmallMedium = "ArPlatformMobileProductsMenu-module--ar-paragraph-xsmall-medium--V--hI";
export var arQuote = "ArPlatformMobileProductsMenu-module--ar-quote--Tx57R";
export var arQuoteMobile = "ArPlatformMobileProductsMenu-module--ar-quote-mobile--Kg6Z5";
export var arTransition = "ArPlatformMobileProductsMenu-module--ar-transition--ZrL6e";
export var arrowButton = "ArPlatformMobileProductsMenu-module--arrowButton--k7IMs";
export var container = "ArPlatformMobileProductsMenu-module--container--IMSZ-";
export var expanded = "ArPlatformMobileProductsMenu-module--expanded--SYL65";
export var show = "ArPlatformMobileProductsMenu-module--show--Us11d";