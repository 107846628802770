// extracted by mini-css-extract-plugin
export var address = "ArFooter-module--address--HcK28";
export var arGradientBlue = "ArFooter-module--ar-gradient-blue--iRz96";
export var arGradientLight = "ArFooter-module--ar-gradient-light--7086E";
export var arH1 = "ArFooter-module--ar-h1--QDIy+";
export var arH1Mobile = "ArFooter-module--ar-h1-mobile--1kOzo";
export var arH2 = "ArFooter-module--ar-h2--4GvDL";
export var arH2Mobile = "ArFooter-module--ar-h2-mobile--ccjpB";
export var arH3 = "ArFooter-module--ar-h3--7rfQC";
export var arH3Mobile = "ArFooter-module--ar-h3-mobile--EesKg";
export var arH4 = "ArFooter-module--ar-h4--ozyvk";
export var arH4Mobile = "ArFooter-module--ar-h4-mobile--z-nkQ";
export var arH5 = "ArFooter-module--ar-h5--1aBoY";
export var arH5Mobile = "ArFooter-module--ar-h5-mobile--5J8DQ";
export var arLinkDesktop = "ArFooter-module--ar-link-desktop--uQEjk";
export var arLinkMobile = "ArFooter-module--ar-link-mobile--Of46g";
export var arParagraphBase = "ArFooter-module--ar-paragraph-base--PSaYG";
export var arParagraphBaseMedium = "ArFooter-module--ar-paragraph-base-medium--8o6RR";
export var arParagraphSmall = "ArFooter-module--ar-paragraph-small--huTPh";
export var arParagraphSmallMedium = "ArFooter-module--ar-paragraph-small-medium--lzCoZ";
export var arParagraphXl = "ArFooter-module--ar-paragraph-xl--2yWox";
export var arParagraphXlMedium = "ArFooter-module--ar-paragraph-xl-medium--TUQtH";
export var arParagraphXsmall = "ArFooter-module--ar-paragraph-xsmall--Ej9vd";
export var arParagraphXsmallMedium = "ArFooter-module--ar-paragraph-xsmall-medium--96q7Q";
export var arQuote = "ArFooter-module--ar-quote--1ycaQ";
export var arQuoteMobile = "ArFooter-module--ar-quote-mobile--Z54dJ";
export var arTransition = "ArFooter-module--ar-transition--96weo";
export var companyInfo = "ArFooter-module--companyInfo--gEBhe";
export var container = "ArFooter-module--container--6lBWq";
export var contentWrapper = "ArFooter-module--contentWrapper--lO0-n";
export var copyrights = "ArFooter-module--copyrights--s1zUL";
export var isCopyright = "ArFooter-module--isCopyright--zz1vb";
export var menu = "ArFooter-module--menu--eVqqc";